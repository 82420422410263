import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SEO from '@codedrop/seo/esm/seo';
import Header from '@codedrop/header/esm/header';
import Footer from '@codedrop/footer/esm/footer';
import Cta from '@codedrop/cta/esm/cta';
import Layout from '@codedrop/layout/esm/layout';
import BlogHeading from '@codedrop/blog-heading/esm/blog-heading';
import Tags from '@codedrop/tag/esm/tags';
import Tag from '@codedrop/tag/esm/tag';
import skpr from '../data/skpr.json';
const { seo, cta, header, footer } = skpr;

export const query = graphql`
  query ($pathSlug: String!) {
    mdx(frontmatter: { path: { eq: $pathSlug } }) {
      frontmatter {
        title
        date
        author
        tag
        tagColor
        path
        tags {
          name
        }
      }
      body
    }
  }
`;

const Post = ({ data: { mdx: post } }) => {
  const { title, date, author, tag, tagColor, tags } = post.frontmatter;
  const { body } = post;
  return (
    <div>
      <SEO title={`Skpr Blog - ${title}`} seo={seo} />
      <Header header={header} />
      <main>
        <Layout className="background--firework section--top-l">
          <BlogHeading blogHeadingClass={`background--${tagColor}-transparent`}>
            <div>
              <Tag tagClass={`tag--${tagColor}`} tagText={tag} />
            </div>
            <h1>{title}</h1>
            <p>
              By {author} • {date}
            </p>
          </BlogHeading>
          <div className="post">
            <MDXRenderer>{body}</MDXRenderer>
            {tags ? (
              <Tags>
                {tags.map((tag) => (
                  <Tag tagClass="tag--orange" tagText={tag.name} />
                ))}
              </Tags>
            ) : (
              ''
            )}
          </div>
        </Layout>
        <Cta cta={cta} />
      </main>
      <Footer footer={footer} />
    </div>
  );
};

export default Post;
